import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArtworkCaptionLite from "../components/artwork-caption-lite"
import AirtableRichtext from "../components/airtable-richtext"
import { smarten } from "../utils/utils"

const WEBSITE_LINK_TEXT = "Artist Website"

const ArtistTemplate = ({ data }) => {
  const {
    Name,
    Artwork,
    Featured_Art,
    Bio,
    Website,
    Artist_Type,
    Artist_Profile_Photo,
  } = data.airtable.data

  const featuredArt =
    !!Featured_Art && !!Featured_Art[0].data.Images && Featured_Art[0].data

  const featuredArtIsLandscape =
    featuredArt &&
    featuredArt.Images.localFiles[0].childImageSharp.fluid.aspectRatio > 1

  return (
    <Layout>
      <SEO title={Name} />
      {featuredArt ? (
        <div
          className={classnames(
            "flex w-full items-center flex-col mb-5 md:mb-10",
            {
              "md:flex-row": !featuredArtIsLandscape,
              "max-w-5xl mx-auto": featuredArtIsLandscape,
            }
          )}
        >
          <div
            className={classnames("text-center", {
              "flex-1": !featuredArtIsLandscape,
            })}
          >
            <h1 className="md:text-5xl my-8">{Name}</h1>
          </div>
          <div
            className={classnames("p-4 w-full", {
              "flex-1": !featuredArtIsLandscape,
            })}
          >
            <Img
              fluid={featuredArt.Images.localFiles[0].childImageSharp.fluid}
              className="mb-4 max-h-80vh"
              imgStyle={{ objectFit: "contain" }}
            />
            <div className="text-center">
              <ArtworkCaptionLite artwork={featuredArt} />
            </div>
          </div>
        </div>
      ) : (
        <h1 className="md:text-5xl text-center">{Name}</h1>
      )}

      {!!Bio && (
        <div className="bg-white py-10">
          <div className="max-w-4xl mx-auto w-full px-4 mb-10 py-10 flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-56">
              {!!Artist_Type && <p>{Artist_Type}</p>}
              {!!Artist_Profile_Photo && (
                <Img
                  className="w-full"
                  fluid={
                    Artist_Profile_Photo.localFiles[0].childImageSharp.fluid
                  }
                />
              )}

              {!!Website && (
                <div className="mt-10">
                  <a href={Website} target="_blank" rel="noreferrer">
                    {WEBSITE_LINK_TEXT}
                  </a>
                </div>
              )}
            </div>
            {!!Bio && (
              <AirtableRichtext
                text={smarten(Bio)}
                className="mt-8 max-w-2xl md:text-lg md:pl-4"
              />
            )}
          </div>
        </div>
      )}

      <div className="max-w-6xl mx-auto w-full px-4 py-20">
        <div className="flex flex-wrap items-center">
          {Artwork &&
            Artwork.filter(
              artwork =>
                artwork.data.Images &&
                artwork.data.Images.localFiles[0].childImageSharp
            ).map((artwork, i) => (
              <Link
                to={`/${artwork.recordId}`}
                key={i}
                className={classnames(
                  "w-1/2 md:w-1/3 lg:w-1/4 pb-6 px-2 md:px-4 no-underline"
                )}
              >
                <Img
                  fluid={
                    artwork.data.Images.localFiles[0].childImageSharp.fluid
                  }
                  className="w-full mb-4"
                />
                <ArtworkCaptionLite artwork={artwork.data} />
              </Link>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default ArtistTemplate

export const query = graphql`
  query ArtistByName($name: String!) {
    airtable(table: { eq: "Artists" }, data: { Name: { eq: $name } }) {
      data {
        Name
        Artist_Type
        Bio
        Website
        Artwork {
          recordId
          data {
            Name
            Images {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Year
            Web_Description
            Artwork_Type
            Unit_Price
          }
        }
        Featured_Art {
          data {
            Name
            Images {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                  }
                }
              }
            }
            Year
            Web_Description
            Artwork_Type
          }
        }
        Artist_Profile_Photo {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
