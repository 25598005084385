import React from "react"

const ArtworkCaptionLite = ({ artwork }) => (
  <p className="text-xs md:text-sm">
    {artwork.Name && <span className="italic">{artwork.Name}</span>}
    {artwork.Year && <>, {artwork.Year}</>}
    {artwork.Artwork_Type && <>, {artwork.Artwork_Type}</>}
    {artwork.Unit_Price && <>, ${artwork.Unit_Price}</>}
  </p>
)

export default ArtworkCaptionLite
