import React from "react"

const AirtableRichtext = ({ className, text }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<p>${text.replace(/\n/g, "</p><p>")}</p>`,
    }}
    className={className}
  />
)

export default AirtableRichtext
